import React, { Component } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import signature from "./Assets/signature.png";
import moment from "moment";

import { connect } from "react-redux";
import { getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";

const styles = StyleSheet.create({
  footerHead: {
    fontFamily: "Helvetica",
    marginTop: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "20px",
    borderTop: "1px solid #aaaaaa",
    borderBottom: "1px solid #aaaaaa",
  },
  footerHeadText: {
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    margin: "0 auto",
    color: "#000",
    fontSize: "8.5px",
    lineHeight: "1.3",
    width: "66%",
    textTransform: "uppercase",
  },
  footerBody: {},
  footerBodyText: {
    fontFamily: "Helvetica",
    fontSize: "7px",
    lineHeight: "1.3",
    marginBottom: "10px",
  },
  footerImageWrapper: {
    fontFamily: "Helvetica",
    marginLeft: "auto",
    width: "120px",
    textAlign: "left",
  },
  footerImage: {
    fontFamily: "Helvetica",
    width: "100%",
    marginBottom: "10px",
  },
  footerAddress: {
    fontFamily: "Helvetica",
    fontSize: "7px",
    lineHeight: "1.3",
  },
  footerFoot: {
    fontFamily: "Helvetica",
    textAlign: "center",
    fontSize: "6px",
    marginTop: "10px",
  },
  policyDetailsUppercase: {
    fontFamily: "Helvetica-Bold",
    color: "rgb(0, 166, 100)",
    textTransform: "uppercase",
    display: "inline",
    fontSize: "8px",
    marginTop: "8px",
  },
  policyDetailsText: {
    fontFamily: "Helvetica",
    color: "rgb(0, 166, 100)",
    display: "inline",
    fontSize: "7px",
    marginTop: "3px",
  },
});

// export const mapStateToProps = (state, ownProps) => {
//   return {
//     locale: getLocale(state),
//   };
// };


export class CertificateFooter extends Component {
  componentDidUpdate(prevProps, prevState) {
    // const { locale, fetchLocale } = this.props;
    // if (!locale) fetchLocale();
  }
  render() {
    const { policy, locale } = this.props;
    moment.locale(locale);
    return (
      <View>
        {policy.description !== "Security &amp; Privacy Liability" && (
          <View>
            <Text style={styles.policyDetailsUppercase}>{translateString(locale, "Vicarious Liability Provision")}:</Text>
            <Text style={styles.policyDetailsText}>
              {translateString(locale, "Vicarious liability coverage is provided to the above-listed Firm through which the Insured is or was doing business or is or was employed, provided such Insured is required to provide vicarious liability coverage for such Firm by reason of liability imposed by law.")}
            </Text>
          </View>
        )}
        <View>
          <Text style={styles.policyDetailsUppercase}>{translateString(locale, "Prior Knowledge Exclusion")}:</Text>
          <Text style={styles.policyDetailsText}>
            {translateString(locale, "This policy does not apply to any Claim or circumstances which may reasonably result in a Claim, whether reported in the application or not, known to any Insured before the effective date of this policy. When this policy replaces, without interruption, a policy previously issued by the Insurer, this exclusion applies only to Claims previously known to an Insured before the inception date of the first policy issued by the Insurer.")}
          </Text>
        </View>
        <View style={styles.footerHead}>
          <Text style={styles.footerHeadText}>{translateString(locale, "All other terms, limits, exclusions and conditions as per master policy")}</Text>
          <Text style={styles.footerHeadText}>{translateString(locale, "underwritten by Zurich Insurance Company Ltd.")}</Text>
        </View>
        <View style={styles.footerBody}>
          <Text style={styles.footerBodyText}>
            {translateString(locale, "IN WITNESS WHEREOF, Zurich Insurance Company Ltd has duly authorized Advocis Broker Services Inc. to execute and issue this Certificate of Insurance.")}
          </Text>
          <Text style={styles.footerBodyText}>{translateString(locale, "Signed on")} {moment(policy.start_date).format("MMMM D, YYYY")} {translateString(locale, "on behalf of insurers by:")}</Text>
        </View>
        <View style={styles.footerImageWrapper}>
          <Image style={styles.footerImage} src={signature} />
          <Text style={styles.footerAddress}>{translateString(locale, "Advocis Broker Services Inc.")}</Text>
          <Text style={styles.footerAddress}>{translateString(locale, "10 Lower Spadina Ave., Suite 700")}</Text>
          <Text style={styles.footerAddress}>{translateString(locale, "Toronto, ON M5V 2Z2")}</Text>
        </View>
        <View>
          <Text style={styles.footerFoot}>
            {translateString(locale, "For purposes of the Insurance Companies Act (Canada), this document was issued in the course of Zurich Insurance Company Ltd’s insurance business in Canada.")}
          </Text>
        </View>
      </View>
    );
  }
}

// export default connect(mapStateToProps, {
//   fetchLocale,
// })(CertificateFooter);
export default CertificateFooter;
