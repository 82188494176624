import React, { Component } from "react";
import FormBottomNav from "../../components/FormBottomNav";
import FormTopNav from "../../components/FormTopNav";
import { FormGroup, FormControl, FormLabel, FormCheck, FormText, ButtonGroup, Button, Row, Col, Container } from "react-bootstrap";
import _informationData from "../../Assets/data/addressData";
import _informationOwnershipData from "../../Assets/data/informationOwnershipData";
import steps from "../../Assets/data/checkoutStepsData";
import PrintFormGroup from "../../components/PrintFormGroup";
import GeneralModal from "../../components/GeneralModal";
import PolicyDetails from "../../components/PolicyDetails";
import { getFormLines, getForms, getQuote, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";
import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../../redux/actions/quote";
import { fetchForms, saveFormLines, saveForms } from "../../redux/actions/forms";
import _ from "lodash";
import _checkoutData from "../../Assets/data/checkoutData";
import EpicAPI from "../../Assets/api/epic";
import xmlescape from "xml-escape";
import validator from "validator";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    forms: getForms(state),
    lines: getFormLines(state),
  };
};

export class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmEmail: false,
      currentStep: 1,
      steps: steps,
      informationData: _informationData,
      informationOwnershipData: _informationOwnershipData,
      flaggedUser: false,
      prevPage: "/checkout",
      nextPage: "/checkout/licensing",
      companyList: [],
      selectedCompanies: [],
      firms: false,
      Modals: [
        {
          oneFirmModal: false,
          moreFirms: false,
        },
      ],
    };

    this.handleSelectedCompanyUnCheck = this.handleSelectedCompanyUnCheck.bind(this);
  }

  componentDidMount() {
    const { quote, fetchQuote, forms, fetchForms, lines } = this.props;
    if (!quote || _.isEmpty(quote)) {
      fetchQuote();
    } else {
      this.setNextPage(quote);
      this.setQuoteState(quote);
    }
    if (lines && !_.isEmpty(lines)) {
      fetchForms(lines[0]);
      this.setState(lines);
    } else this.getLineIDs();

    if (forms && !_.isEmpty(forms)) this.setFormsState(forms);
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, forms, lines, locale, fetchLocale } = this.props;
    if (!_.isEqual(lines, prevProps.lines)) this.setState(lines);
    if (!locale) fetchLocale();

    if (!_.isEqual(quote, prevProps.quote)) {
      this.setNextPage(quote);
      this.setQuoteState(quote);
    }
    if (!_.isEqual(forms, prevProps.forms)) this.setFormsState(forms);
  }
  setQuoteState(quote) {
    this.setState({
      ...quote,
    });
  }
  setNextPage(quote) {
    let skipLicensing = true;
    quote.policies.forEach((policy) => {
      if (policy.code.substring(0, 2) === "EA" || policy.code.substring(0, 2) === "EB" || policy.code.substring(0, 2) === "ED") {
        skipLicensing = false;
      }
    });
    if (skipLicensing) {
      this.setState({ nextPage: "/checkout/review" });
    }
  }

  setFormsState(forms) {
    let checkoutData = _checkoutData;
    // console.log(forms);
    this.setState({
      forms,
      checkoutData,
    });
  }

  getLineIDs = async () => {
    const { quote, saveFormLines } = this.props;
    let lines = [];
    if (quote && quote.activePolicies) {
      for (let p of quote.activePolicies) {
        let lineID = await EpicAPI.getLine(p);
        lines.push(lineID);
      }
    }
    saveFormLines(lines);
  };

  handleModalShow = (key, e) => {
    // e.preventDefault();
    console.log(e);
    let Modals = { ...this.state.Modals };
    Modals[0][key] = true;
    this.setState({
      Modals,
    });
  };

  handleModalClose = (key) => {
    let Modals = { ...this.state.Modals };
    Modals[0][key] = false;
    this.setState({
      Modals,
    });
  };

  handleCompanyEdit = (e) => {
    const lines = e.target.value.split(/\r\n|\r|\n/g);
    let companyList = lines.filter((line) => line.length > 0);
    // reduce duplicates
    companyList = _.uniq(companyList);
    this.setState({ companyList }, this.updateSelectedCompany);
  };

  updateSelectedCompany = () => {
    const _companyList = this.state.companyList;
    const _selectedCompanies = this.state.selectedCompanies;
    if (_selectedCompanies.length > 0) {
      const listDiff = _.difference(_selectedCompanies, _companyList);
      console.log("diff is : " + listDiff);
      if (listDiff.length > 0) {
        let index = _selectedCompanies.indexOf(listDiff[0]);
        _selectedCompanies.splice(index, 1);
        this.setState({ selectedCompanies: _selectedCompanies });
        // console.log("companylist is : \r\n" + this.state.companyList + "\r\n selected company list is : \r\n" + this.state.selectedCompanies);
      }
    }
  };

  handleSelectedCompanyUnCheck = (e) => {
    let { selectedCompanies } = this.state;
    if (e.target.checked) {
      if (!selectedCompanies.includes(e.target.value)) selectedCompanies.push(e.target.value);
    } else {
      _.pull(selectedCompanies, e.target.value);
    }
    this.setState({ selectedCompanies: selectedCompanies });
  };

  // handleSelectCompanyRemove = (e) => {
  //   const removedCompany = e.target.parentElement.querySelector(".form-label").innerHTML;
  //   let companyList = [...this.state.companyList];
  //   companyList.splice(companyList.indexOf(removedCompany), 1);
  //   // console.log(companyList);
  //   this.setState({ companyList });
  //   // e.target.closest('.companies').querySelector('#companyList').value = (e.target.closest('.companies').querySelector('#companyList').value).replace(removedCompany, e.target.closest('.companies').querySelector('#companyList').value);
  //   let textarea = "";

  //   companyList.forEach((company) => {
  //     textarea += company + "\n";
  //   });
  //   // console.log(textarea);
  //   e.target.closest(".companies").querySelector("#companyList").value = textarea;
  // };

  handleChange = (event, key) => {
    // console.log(event.target.id)
    // this.setState({
    //   [event.target.id]: event.target.value
    // });
  };

  handleButtonChange = (id, val) => {
    this.setState({
      [id]: val,
    });
  };

  activateButton = (e) => {
    // console.log(e);
    let val = e.target.innerHTML;
    if (e.target.classList.contains("active")) {
      e.target.classList.remove("active");
      val = "";
    } else {
      e.target.parentElement.querySelectorAll(".btn").forEach((a) => {
        a.classList.remove("active");
      });
      e.target.classList.add("active");
    }
    this.handleButtonChange(e.target.parentNode.id, val);
  };

  buttonDisabled = (quote) => {
    let valid = true;
    valid = valid && quote.City && quote.City !== "" ? true : false;
    valid = valid && quote.Street && quote.Street !== "" ? true : false;
    valid = valid && quote.Province && quote.Province !== "" ? true : false;
    // valid = valid && quote.PostalCode && quote.PostalCode !== "" ? true : false;
    valid = valid && quote.PostalCode && validator.isPostalCode(quote.PostalCode, "CA");
    // console.log("validator", (valid && quote.City && quote.City !== ""), valid && quote.Street && quote.Street !== "", valid && quote.Province && quote.Province !== "", valid && quote.PostalCode && quote.PostalCode !== "");
    return !valid;
  };

  handleSubmit = async (event, firms = false) => {
    event.preventDefault();
    const { saveForms, saveQuote, quote } = this.props;
    const { forms, City, Street, Province, PostalCode, companyList, corporationOwned, legalName, employedAgents, selectedCompanies } = this.state;

    EpicAPI.updateClientAddress({
      id: quote.clientID,
      address: Street,
      city: City,
      province: Province,
      postal: PostalCode.replace("-", ""),
      email: quote.email,
    });

    let _forms = forms;
    let _quote = { ...quote };
    _quote.client_data = {};
    _.each(_forms.screens, (s) => {
      if (s.ScreenName === "Client Information") {
        _.each(s.fields, (f) => {
          if (f.FieldName === "City") {
            f.FieldValue = xmlescape(City);
            _quote.client_data.city = xmlescape(City);
          }
          if (f.FieldName === "Street") {
            f.FieldValue = xmlescape(Street);
            _quote.client_data.address = xmlescape(Street);
          }
          if (f.FieldName === "Province") {
            f.FieldValue = xmlescape(Province);
            _quote.client_data.province = xmlescape(Province);
          }
          if (f.FieldName === "PostalCode") {
            _quote.client_data.postal = xmlescape(PostalCode);
            f.FieldValue = xmlescape(PostalCode);
          }
        });
      }
      if (s.ScreenName === "Applicant Information") {
        _.each(s.fields, (f) => {
          if (f.FieldName === "Firms") f.FieldValue = xmlescape(companyList.join(", "));
          if (f.FieldName === "Name") f.FieldValue = xmlescape(selectedCompanies.join(", "));
          if (f.FieldName === "CorpOwnership") f.FieldValue = corporationOwned === "Yes" ? "Y" : "N";
          if (f.FieldName === "Employees") f.FieldValue = employedAgents === "Yes" ? "Y" : "N";
          if (f.FieldName === "CorpName") f.FieldValue = xmlescape(legalName) ?? "";
          if (f.FieldName === "MembID") f.FieldValue = xmlescape(quote.memberId) ?? "";
          if (f.FieldName === "Partner") f.FieldValue = !!quote.coupon && quote.coupon.corporation ? xmlescape(quote.coupon.corporation) : "";
          if (f.FieldName === "Code") f.FieldValue = !!quote.coupon && quote.coupon.type ? xmlescape(quote.coupon.type) : "";
          if (f.FieldName === "AdvMember") f.FieldValue = !!quote.memberId ? "Yes" : "No";
        });
      }
    });

    this.setState({ isLoading: true });
    if (legalName === undefined) {
      _quote.legalName = "";
    }
    try {
      // set isLoading during submitting
      this.setState({ isLoading: true });
      saveForms(_forms)
        .then((d) => {
          if (companyList) {
            _quote.companyList = companyList;
          }
          if (selectedCompanies) {
            _quote.selectedCompanies = selectedCompanies;
          }
          saveQuote(_quote);
          //if more than 1 firm, show modal
          if (this.state.firms) {
            _.each(_quote.activePolicies, (policy) => {
              // console.log("adding REFR activity to policy ", policy);
              EpicAPI.insertActivity({
                clientID: _quote.clientID,
                policyID: policy,
                code: "REFR",
                type: "Policy",
                // pass province to insertActivity, for setting brancCode for Quebec client.
                province: Province,
              });
            });
            this.handleModalShow("moreFirms", null);
          } else {
            this.props.history.push(this.state.nextPage);
          }
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { informationData, informationOwnershipData } = this.state;
    const { quote, locale } = this.props;
    return (
      <Container className="Information" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} />
            <div className="formDescription">
              <div className="mobilePricing">
                <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                <PolicyDetails />
              </div>
              <h3>{translateString(locale, "Firm Information")}</h3>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="companies">
                <div className="PrintFormGroup">
                  <FormGroup controlId="companyList" className="formatted">
                    <label>{translateJSXElement(locale, "information.list")}</label>
                    <FormControl autoFocus={true} as="textarea" maxLength={299} onChange={this.handleCompanyEdit} rows="5" />
                  </FormGroup>
                  {this.state.companyList.length > 0 ? (
                    <div className="selectedCompanies__description">
                      <span>
                        {translateString(
                          locale,
                          "Please indicate which of these firms require their names to be shown on your Certificate of Insurance (for vicarious liability coverage)."
                        )}
                      </span>
                      <br />
                      <p className="bold underline">{translateString(locale, "All your firms")}</p>
                    </div>
                  ) : null}
                  {this.state.companyList.map((company, j) => {
                    return (
                      <FormGroup controlId="selectedCompanies" className="checkList" key={j}>
                        <FormCheck
                          onChange={this.handleSelectedCompanyUnCheck}
                          // key={this.props.parent + "_Check" + j}
                          key={company}
                          //defaultChecked={false}
                          value={company}
                        />
                        <FormLabel key={this.props.parent + "_Label" + j}>{company}</FormLabel>
                        {/* <i className="fas fa-times-circle" onClick={this.handleSelectCompanyRemove}></i> */}
                      </FormGroup>
                    );
                  })}
                  {this.state.selectedCompanies.length > 0 ? (
                    <div className="selectedCompanies__description">
                      <p className="bold underline">{translateString(locale, "Firms on your certificate")}</p>
                    </div>
                  ) : null}
                  {this.state.selectedCompanies.map((company, j) => {
                    return (
                      <FormGroup className="checkList" key={j}>
                        <FormText key={company}>{company}</FormText>
                      </FormGroup>
                    );
                  })}
                </div>
              </div>
              {/* rewrite this with state/map -- rid activateButton */}
              {this.state.companyList.length > 0 ? (
                <div className="ownership Line Grey top">
                  <div className="description">
                    <p className="mt0">{translateJSXElement(locale, "locale.ownership")}</p>
                  </div>
                  <FormGroup controlId="ownership" className="formatted">
                    <div className="buttons FieldWrapper">
                      <ButtonGroup className="radioWrap" id="ownership">
                        <Button
                          variant="radioButtons"
                          key="ownership_radio0"
                          onClick={(e) => {
                            this.activateButton(e);
                            this.setState({ firms: false });
                          }}>
                          {translateString(locale, "No")}
                        </Button>
                        <Button
                          variant="radioButtons"
                          key="ownership_radio1"
                          onClick={(e) => {
                            this.activateButton(e);
                            if (this.state.Modals[0].oneFirmModal === false && this.state.ownership !== "Yes, 1 firm") {
                              this.handleModalShow("oneFirmModal", e);
                            }
                            this.setState({ firms: false });
                          }}>
                          {translateString(locale, "Yes, 1 firm")}
                        </Button>
                        <Button
                          variant="radioButtons"
                          key="ownership_radio2"
                          onClick={(e) => {
                            this.activateButton(e);
                            this.setState({ firms: !this.state.firms });
                          }}>
                          {translateString(locale, "Yes, more than 1 firm")}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </FormGroup>
                  {this.state.ownership === "Yes, 1 firm" ? (
                    <PrintFormGroup parent="1firm" data={informationOwnershipData} handleChange={this.handleChange} className="formatted" />
                  ) : null}
                </div>
              ) : null}
              <div className="informationFields Line Grey top">
                <p className="bold mt0">{translateString(locale, "Address of Insured")}</p>
                <PrintFormGroup parent="informationAddress" data={informationData} handleChange={this.handleChange} className="formatted" />
              </div>
              <FormBottomNav
                disabled={quote && this.buttonDisabled(quote)}
                isLoading={this.state.isLoading}
                prevText={translateString(locale, "Go Back")}
                prevPage={this.state.prevPage}
                className={this.state.flaggedUser ? "flaggedUser Line Green top" : "Line Green top"}
                required={["Street Address", "City", "Province", "Postal Code"]}></FormBottomNav>
              {/* <Link className="tempNext" to={this.state.nextPage}>
                Force Next Step
                </Link> */}
            </form>
          </Col>
          <Col lg={"auto"} className="sideBar">
            <PolicyDetails />
          </Col>
        </Row>
        <GeneralModal show={this.state.Modals[0].oneFirmModal}>
          <h2>{translateString(locale, "Important Notice")}</h2>
          {translateJSXElement(locale, "information.oneFirmModal")}
          {translateJSXElement(locale, "information.purchase")}
          <Button
            variant="primary"
            onClick={(e) => {
              this.handleModalClose("oneFirmModal", e);
            }}>
            {translateString(locale, "OK")}
          </Button>
        </GeneralModal>
        <GeneralModal show={this.state.Modals[0].moreFirms}>
          <h2>{translateString(locale, "Important Notice")}</h2>
          <p>
            {translateString(
              locale,
              "Your application has been referred to an underwriter for review. Once the review has been completed we will contact you with the next steps."
            )}
          </p>
          <div className="d-flex justify-content-between">
            <Button
              variant="primary"
              onClick={(e) => {
                window.location.href = "https://www.advocisinsurance.ca/";
              }}>
              {translateString(locale, "OK")}
            </Button>
          </div>
        </GeneralModal>
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchForms,
  saveForms,
  saveFormLines,
})(Checkout);
