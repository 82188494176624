import React, { Component } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import CertificateProvincialTable from "./CertificateProvincialTable";
import CertificateFooter from "./CertificateFooter";
import CertificateHeader from "./CertificateHeader";
import CertificateInfoBox from "./CertificateInfoBox";
import CertificateCoverageDetails from "./CertificateCoverageDetails";


import { connect } from "react-redux";
import { getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";

// export const mapStateToProps = (state, ownProps) => {
//   return {
//     locale: getLocale(state),
//   };
// };

const styles = StyleSheet.create({
  page: {
    padding: "16px",
  },
});
export class CertificateGeneratorPage extends Component {
  componentDidUpdate(prevProps, prevState) {
    // const { locale, fetchLocale } = this.props;
    // if (!locale) fetchLocale();
  }
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPolicyNumber = (policyScreen, policyID) => {
    // console.log({ policyID, policyScreen });
    if (policyScreen.FieldValue && policyScreen.FieldValue !== "") {
      return policyScreen.FieldValue;
    } else {
      return policyID;
    }
  };

  htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };
  getTitle = (code, locale) => {
    switch (code.substr(0, 2)) {
      case "EA":
        return translateString(locale,"Advocis Protective Association E&O Plan");
      case "EB":
        return translateString(locale,"Advocis Protective Association E&O Plan");
      case "EC":
        return translateString(locale,"Advocis Protective Association E&O Plan");
      case "ED":
        return translateString(locale,"Advocis Protective Association E&O Plan");
      case "SP":
        return translateString(locale,"Advocis Protective Association E&O Plan");
      default:
        return translateString(locale,"Advocis Protective Association E&O Plan");
    }
  };
  render() {
    const clientInformationScreen = this.props.CustomForm.screens[0];
    const policyScreen = this.props.CustomForm.screens[1].fields[0];

      const { policy, CustomForm, quoteDetails, pricing, locale } = this.props;
    return (
      <Document>
        <Page style={styles.page} size="A4">
          <CertificateHeader
            policyNum={this.getPolicyNumber(policyScreen, policy.id)}
            locale={locale}
            // title={this.htmlDecode(this.props.CustomForm.FormName)}
            title={this.getTitle(policy.code, locale)}
            subtitle={translateString(locale,"Certificate of Insurance - Individual E&O Liability")}
            subtitle2={policy.code.substr(0, 2) === "SP" ? translateString(locale,"Security & Privacy Liability") : false}
            afterPol={policy.code.substr(0, 2) === "SP" ? translateString(locale," Endorsement #1 is as listed below:") : false}
          />
          <CertificateInfoBox CustomForm={CustomForm} quoteDetails={quoteDetails} policy={policy} locale={locale} />
          <CertificateCoverageDetails policy={policy} quoteDetails={quoteDetails} pricing={pricing} locale={locale} />
          <CertificateProvincialTable CustomForm={CustomForm} policy={policy} quoteDetails={quoteDetails} pricing={pricing} locale={locale} />
          <CertificateFooter policy={policy} locale={locale} />
        </Page>
      </Document>
    );
  }
}

// export default connect(mapStateToProps, {
//   fetchLocale,
// })(CertificateGeneratorPage);
export default CertificateGeneratorPage;
