const licensing_iiroc = [
  {
    securities: [
      {
        details: [
          {
            controlId: "securities",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Securities",
                default: false,
                dependencies: [
                  {
                    securitiesProvince: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "securitiesProvince",
                            formLabel: "Please indicate where you are licensed:",
                            FormControl: [
                              {
                                type: "checkbox",
                                checkbox: [
                                  {
                                    options: [
                                      "Alberta",
                                      "British Columbia",
                                      "Manitoba",
                                      "New Brunswick",
                                      "Newfoundland and Labrador",
                                      "Northwest Territories",
                                      "Nova Scotia",
                                      "Nunavut",
                                      "Ontario",
                                      "Prince Edward Island",
                                      "Québec",
                                      "Saskatchewan",
                                      "Yukon Territory",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    incomeTax: [
      {
        details: [
          {
            controlId: "incomeTax",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Income Tax Preparation",
                default: false,
                dependencies: [
                  {
                    incomeTaxProvince: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "incomeTaxProvince",
                            formLabel: "Please indicate where you are licensed:",
                            FormControl: [
                              {
                                type: "checkbox",
                                checkbox: [
                                  {
                                    options: [
                                      "Alberta",
                                      "British Columbia",
                                      "Manitoba",
                                      "New Brunswick",
                                      "Newfoundland and Labrador",
                                      "Northwest Territories",
                                      "Nova Scotia",
                                      "Nunavut",
                                      "Ontario",
                                      "Prince Edward Island",
                                      "Québec",
                                      "Saskatchewan",
                                      "Yukon Territory",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    executorEstate: [
      {
        details: [
          {
            controlId: "executorEstate",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Executor of Estate",
                default: false,
                dependencies: [
                  {
                    executorEstateProvince: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "executorEstateProvince",
                            formLabel: "Please indicate where you are licensed:",
                            FormControl: [
                              {
                                type: "checkbox",
                                checkbox: [
                                  {
                                    options: [
                                      "Alberta",
                                      "British Columbia",
                                      "Manitoba",
                                      "New Brunswick",
                                      "Newfoundland and Labrador",
                                      "Northwest Territories",
                                      "Nova Scotia",
                                      "Nunavut",
                                      "Ontario",
                                      "Prince Edward Island",
                                      "Québec",
                                      "Saskatchewan",
                                      "Yukon Territory",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    notaryPublic: [
      {
        details: [
          {
            controlId: "notaryPublic",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Notary Public",
                default: false,
                dependencies: [
                  {
                    notaryPublicProvince: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "notaryPublicProvince",
                            formLabel: "Please indicate where you are licensed:",
                            FormControl: [
                              {
                                type: "checkbox",
                                checkbox: [
                                  {
                                    options: [
                                      "Alberta",
                                      "British Columbia",
                                      "Manitoba",
                                      "New Brunswick",
                                      "Newfoundland and Labrador",
                                      "Northwest Territories",
                                      "Nova Scotia",
                                      "Nunavut",
                                      "Ontario",
                                      "Prince Edward Island",
                                      "Québec",
                                      "Saskatchewan",
                                      "Yukon Territory",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    commissionerOaths: [
      {
        details: [
          {
            controlId: "commissionerOaths",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Commissioner of Oaths",
                default: false,
                dependencies: [
                  {
                    commissionerOathsProvince: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "commissionerOathsProvince",
                            formLabel: "Please indicate where you are licensed:",
                            FormControl: [
                              {
                                type: "checkbox",
                                checkbox: [
                                  {
                                    options: [
                                      "Alberta",
                                      "British Columbia",
                                      "Manitoba",
                                      "New Brunswick",
                                      "Newfoundland and Labrador",
                                      "Northwest Territories",
                                      "Nova Scotia",
                                      "Nunavut",
                                      "Ontario",
                                      "Prince Edward Island",
                                      "Québec",
                                      "Saskatchewan",
                                      "Yukon Territory",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    feeServicePlanning: [
      {
        details: [
          {
            controlId: "feeServicePlanning",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Fee for Service Planning",
                default: false,
                dependencies: [
                  {
                    feeServicePlanningProvince: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "feeServicePlanningProvince",
                            formLabel: "Please indicate where you are licensed:",
                            FormControl: [
                              {
                                type: "checkbox",
                                checkbox: [
                                  {
                                    options: [
                                      "Alberta",
                                      "British Columbia",
                                      "Manitoba",
                                      "New Brunswick",
                                      "Newfoundland and Labrador",
                                      "Northwest Territories",
                                      "Nova Scotia",
                                      "Nunavut",
                                      "Ontario",
                                      "Prince Edward Island",
                                      "Québec",
                                      "Saskatchewan",
                                      "Yukon Territory",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    divorceSpecialist: [
      {
        details: [
          {
            controlId: "divorceSpecialist",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Certified Divorce Specialist",
                default: false,
                dependencies: [
                  {
                    divorceSpecialistProvince: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "divorceSpecialistProvince",
                            formLabel: "Please indicate where you are licensed:",
                            FormControl: [
                              {
                                type: "checkbox",
                                checkbox: [
                                  {
                                    options: [
                                      "Alberta",
                                      "British Columbia",
                                      "Manitoba",
                                      "New Brunswick",
                                      "Newfoundland and Labrador",
                                      "Northwest Territories",
                                      "Nova Scotia",
                                      "Nunavut",
                                      "Ontario",
                                      "Prince Edward Island",
                                      "Québec",
                                      "Saskatchewan",
                                      "Yukon Territory",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    other: [
      {
        details: [
          {
            controlId: "other",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Other",
                default: false,
                dependencies: [
                  {
                    otherText: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "otherText",
                            FormControl: [
                              {
                                autoFocus: false,
                                type: "text",
                                placeholder: "",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export default licensing_iiroc;
