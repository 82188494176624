import React, { Component } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import PDFLogo from "./Assets/PDFLogo";
import PDFLogoFrench from "./Assets/PDFLogoFrench";
import { isInteger } from "lodash";

import { connect } from "react-redux";
import { getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";

// export const mapStateToProps = (state, ownProps) => {
//   return {
//     locale: getLocale(state),
//   };
// };

const styles = StyleSheet.create({
  mainView: {
    fontFamily: "Helvetica-Bold",
  },
  topBar: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  h1: {
    color: "#000",
    fontSize: "14px",
    lineHeight: "1.15",
    fontWeight: "bold",
    textWrap: "wrap",
  },
  h2: {
    color: "#000",
    fontSize: "12px",
    lineHeight: "1.15",
    marginTop: "3px",
    textWrap: "wrap",
  },
  header: {
    flexDirection: "column",
    marginBottom: "5px",
    textAlign: "left",
    width: "100%",
    flexWrap: "wrap",
    textWrap: "wrap",
  },
  policyLine: {
    fontSize: "8px",
    marginTop: "20px",
    fontWeight: "bold",
    color: "#000",
  },
  policyLineAccent: {
    color: "rgb(0, 166, 100)",
  },
});
export class CertificateHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    // const { locale, fetchLocale } = this.props;
    // if (!locale) fetchLocale();
  }

  render() {
    const { locale } = this.props;
    return (
      <View style={styles.mainView}>
        <View style={styles.topBar}>
          <View style={styles.header}>
            <Text style={styles.h1}>{this.props.title}</Text>
            <Text style={styles.h2}>{this.props.subtitle}</Text>
            {this.props.subtitle2 && <Text style={styles.h2}>{this.props.subtitle2}</Text>}
          </View>
          {locale == "EN" && <PDFLogo />}
          {locale == "FR" && <PDFLogoFrench />}
        </View>
        <Text style={styles.policyLine}>
          {translateString(locale, "This Certificate of Insurance is evidence of insurance described under")}{" "}
          <Text style={styles.policyLineAccent}>
            {!isInteger(this.props.policyNum) ? translateString(locale, "Master") + " " : ""}
            {translateString(locale, "Policy No.")} {this.props.policyNum}
          </Text>
          {this.props.afterPol}
        </Text>
      </View>
    );
  }
}

// export default connect(mapStateToProps, {
//   fetchLocale,
// })(CertificateHeader);
export default CertificateHeader;
